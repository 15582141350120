export default  {
  // hash: 'SHAKE_128',
  // height: 10,
  // wallet: {
  //   open: false,
  //   address: null,
  // },
  network: 'mainnet',
  qrllib: false,
  bookmarks: [],
  xmss: null,
};
