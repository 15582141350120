
import { IonApp, IonSelectOption, IonButton, IonSelect, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { addOutline, addSharp, bookmarkOutline, bookmarkSharp, lockOpenOutline, lockOpenSharp, homeOutline, homeSharp, globeOutline, globeSharp, cogOutline, cogSharp, hardwareChipOutline, hardwareChipSharp, peopleOutline, peopleSharp } from 'ionicons/icons';
import { Plugins } from '@capacitor/core'
import state from './store';

const { Storage } = Plugins

export default defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    // IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
    IonSelectOption,
    IonSelect,
    IonButton
  },
  data () {
    return {
      labels: state.bookmarks,
      sharedState: state
    }
  },
  methods: {
    fill() {
      if (this.sharedState.network !== 'offline') {
        return 'solid';
      }
      return 'outline';
    },
  },
  async created () {
    const getObject = async function() {
      const ret = await Storage.get({ key: 'bookmarks' });
      if (ret.value === null) {
        console.log('No bookmarks yet...')
        return []
      } else {
        console.log('Bookmarks found... fetching')
        return JSON.parse(ret.value)
      }
    }

    const loadBookmarks = async function() {
      const bookmarks = await getObject()
      return bookmarks
    }

    const bookmarks = await loadBookmarks()
    this.sharedState.bookmarks = bookmarks
  
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const customAlertOptions: any = {
      header: 'Network',
    }
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Home',
        url: '/home',
        iosIcon: homeOutline,
        mdIcon: homeSharp
      },
      {
        title: 'Explore',
        url: '/explorer',
        iosIcon: globeOutline,
        mdIcon: globeSharp
      },
      {
        title: 'Open',
        url: '/open',
        iosIcon: lockOpenOutline,
        mdIcon: lockOpenSharp
      },
      {
        title: 'New',
        url: '/new',
        iosIcon: addOutline,
        mdIcon: addSharp
      },
      // {
      //   title: 'Tools',
      //   url: '/folder/Tools',
      //   iosIcon: cogOutline,
      //   mdIcon: cogSharp
      // },
      // {
      //   title: 'Contacts',
      //   url: '/folder/Contacts',
      //   iosIcon: peopleOutline,
      //   mdIcon: peopleSharp
      // },
      // {
      //   title: 'Staking',
      //   url: '/folder/Contacts',
      //   iosIcon: hardwareChipOutline,
      //   mdIcon: hardwareChipSharp
      // }
    ];
  

    const path = window.location.pathname.split('/')[1];
    if (path === 'a' || path === 'explorer' || path === 'block' || path === 'tx') { selectedIndex.value = 1 }
    // const path = window.location.pathname.split('folder/')[1];
    // if (path !== undefined) {
    //   selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    // }
    
    return { 
      selectedIndex,
      appPages, 
      addOutline, 
      addSharp, 
      bookmarkOutline, 
      bookmarkSharp, 
      lockOpenOutline, 
      lockOpenSharp, 
      homeOutline, 
      homeSharp, 
      globeOutline, 
      globeSharp, 
      cogOutline, 
      cogSharp, 
      hardwareChipOutline, 
      hardwareChipSharp,
      peopleOutline,
      peopleSharp,
      router,
      customAlertOptions,
      isSelected: (url: string) => url === route.path ? 'selected' : ''
    }
  },
  watch: {
    'sharedState.bookmarks': function (oldState, newState) {
      this.labels = this.sharedState.bookmarks
    }
  },
});
